
.center {
    text-align: center;
}

.content {
    font-size: 16px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    overflow: hidden;
    overflow-y: auto;    
    width: 100%;
    height: 450px;
    overflow: hidden;
    max-width: 600px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

#fileNameField {
    margin-top: 4px;
    border-width: 1px;
    height: 34px;
    line-height: 34px;
    border-radius: 8px;
    text-indent: 12px;
    font-size: 20px;
    outline: 0 !important;
    width: 95%;
}

#uploadSuccess {
    display: none;
    margin-top: 20px;
}

#successMessage {
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    top: 3px;
    padding: 2px 4px 2px 30px;
    border: 2px solid #61AC11;
    background: transparent url("/images/alerts_save_success.png") no-repeat scroll 2px 2px;
}

#uploadButton {
    height: 35px;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin: 20px auto;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: white;
    border: 1px solid grey;
    text-align: center;
    line-height: 35px;
    width: 200px;
    background: #aece6b;
    background: -moz-linear-gradient(top, #aece6b 0%, #75a919 50%, #5a8c07 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#aece6b), color-stop(50%,#75a919), color-stop(100%,#5a8c07));
    background: -webkit-linear-gradient(top, #aece6b 0%,#75a919 50%,#5a8c07 100%);
    background: -o-linear-gradient(top, #aece6b 0%,#75a919 50%,#5a8c07 100%);
    background: -ms-linear-gradient(top, #aece6b 0%,#75a919 50%,#5a8c07 100%);
    background: linear-gradient(to bottom, #aece6b 0%,#75a919 50%,#5a8c07 100%);

    &.clicked {
        background: #1d62c1;
        background: -moz-linear-gradient(top, #1d62c1 0%, #3d7bd4 50%, #6197ea 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#1d62c1), color-stop(50%,#3d7bd4), color-stop(100%,#6197ea));
        background: -webkit-linear-gradient(top, #1d62c1 0%,#3d7bd4 50%,#6197ea 100%);
        background: -o-linear-gradient(top, #1d62c1 0%,#3d7bd4 50%,#6197ea 100%);
        background: -ms-linear-gradient(top, #1d62c1 0%,#3d7bd4 50%,#6197ea 100%);
        background: linear-gradient(to bottom, #1d62c1 0%,#3d7bd4 50%,#6197ea 100%);
    }
}

#expiredMessage {
    margin-top: 200px;
}
