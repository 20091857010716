.smi-grid-search {
    margin-left: auto;
    display:flex;
    align-items:center;

    button {
        border: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }

    .search-input {
        padding: 5px;
        border: 2px solid #9da2a6;
        border-radius: 2px;
        width: 200px;
    }

    .search-btn {
        width: 66px;
        height: 30px;
        background: url('../../images/search-button.png') no-repeat top left;
        margin-left: 8px;

        &:hover {
            background-position: 0 -31px;
        }

        &:active {
            background-position: 0 -62px;
        }
    }

    .clear-btn {
        margin-top: 2px;
        margin-right: 2px;
    }
}
