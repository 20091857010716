#myAlert {
    height: 100%;

    .top-section {
        display: inline-flex;
        justify-content: space-between;
        width: 95%;
        margin: 15px 2% 5px 2%;

        .tab-options {
            text-align: center;
            width: 280px;
            border-top: 1px solid #555;
            -moz-border-radius: 20px;
            -webkit-border-radius: 20px;
            border-radius: 20px;
            line-height: 30px;
            height: 30px;
            padding: 3px 10px 0 10px;
            background: -moz-linear-gradient(top,#c5cdd0 0%,#f1f1f1 100%);
            background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#c5cdd0),color-stop(100%,#f1f1f1));
            background: -webkit-linear-gradient(top,#c5cdd0 0%,#f1f1f1 100%);
            background: -o-linear-gradient(top,#c5cdd0 0%,#f1f1f1 100%);
            background: -ms-linear-gradient(top,#c5cdd0 0%,#f1f1f1 100%);
            background: linear-gradient(to bottom,#c5cdd0 0%,#f1f1f1 100%);

            input[type=radio] {
                border: 0;
                width: 1.3em;
                height: 1.3em;
                position: relative;
                float: left;
            }

            label {
                float: left;
                margin-right: 8px;
            }
        }

        .alert-type-dropdown {
            width: 250px;

            .k-input {
                height: auto;
            }
        }
    }

    #grid-section {
        height: calc(100% - 40px);

        .smi-grid {
            height: 100%;

            .grid-toolbar {
                display: none;
            }
        }
    }

    
}

.my-alert-dialog {
    .k-dialog-buttongroup {
        justify-content: unset;

        .error-message {
            flex-grow: 18;
            text-align: center;
            color:red;
        }

        .action-button {
            flex-grow: 1;
            display: flex;
            justify-content: end;

            button {
                margin-left: 5px;
                border-radius: 4px;
                border-color: #ceced2;
                color: #515967;
                background-color: #f3f3f4;
                background-position: 50% 50%;
                padding: 2px 7px;
                box-sizing: border-box;
                border-width: 1px;
                border-style: solid;
                background-repeat: repeat-x;
                line-height: 1.72em;
                background-color: #d0d0dd;
            }

            .k-primary {
                color: #fff;
                border-color: #177bba;
                background-color: #2198e3;
                background-image: none;
                background-image: none,linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(255,255,255,.2) 100%)
            }

            .k-primary:hover, .k-primary.k-state-hover {
                color: #fff;
                border-color: #7b889f;
                background-color: #1b8dd6;
                background-image: none;
                background-image: none,linear-gradient(to bottom,rgba(255,255,255,.3) 0,rgba(255,255,255,0) 100%)
            }

            .k-primary:active, .k-primary.k-state-active {
                color: #fff;
                border-color: #1a87cd;
                background-color: #1a84c7;
                background-image: none;
                background-image: none,linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(255,255,255,.2) 100%)
            }

            .k-primary:focus:active:not(.k-state-disabled):not([disabled]) {
                box-shadow: 0 0 3px 0 #1984c8
            }

            .k-primary[disabled], .k-state-disabled .k-primary, .k-state-disabled .k-primary:hover, .k-primary.k-state-disabled, .k-primary.k-state-disabled:hover {
                color: #e6e6e6;
                border-color: #e6e6e6;
                background-color: #269ae4;
                box-shadow: none;
                background-image: none;
                background-image: none,linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(255,255,255,.2) 100%)
            }

            .cancel-button {
                border-radius: 4px;
                border-color: #ceced2;
                color: #515967;
                background-color: #d0d0dd;
                background-position: 50% 50%;
                background-image: none;
                background-image: none,linear-gradient(to bottom,rgba(255,255,255,.45) 0,rgba(255,255,255,0) 100%);

                &:hover {
                    color: #263248;
                    border-color: #bebec3;
                    background-color: #b6bdca;
                    background-image: none;
                    background-image: none,linear-gradient(to bottom,rgba(255,255,255,.3) 0,rgba(255,255,255,0) 100%);
                }

                &:active {
                    color: #fff;
                    background-color: #1984c8;
                    border-color: #1a87cd;
                    background-image: none;
                    background-image: none,linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(255,255,255,.2) 100%);
                    box-shadow: none;
                }
            }

            .cancel-button.k-state-hover {
                color: #263248;
                border-color: #bebec3;
                background-color: #b6bdca;
                background-image: none;
                background-image: none,linear-gradient(to bottom,rgba(255,255,255,.3) 0,rgba(255,255,255,0) 100%);
            }

            .cancel-button.k-state-active {
                color: #fff;
                background-color: #1984c8;
                border-color: #1a87cd;
                background-image: none;
                background-image: none,linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(255,255,255,.2) 100%);
                box-shadow: none;

                &:hover {
                    color: #fff;
                    border-color: #7b889f;
                    background-color: #1b8dd6;
                }
            }
        }
    }



    .k-grid-update, .k-grid-cancel {
        height: 25px;
        padding: 0 0 0 25px;
        width: 80px;
        min-width: 0;
        border: none;
        box-shadow: none;
        overflow: hidden;
        display: block;
        margin: 0 auto;


        &:hover {
            background-position: 0 -25px;
        }

        &:active {
            background-position: 0 -50px;
        }

        .k-icon {
            display: none;
        }

        &.disabled {
            background-position: top left !important;
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .k-grid-update {
        background: url('../../images/update-sprites.png') no-repeat top left transparent;
        z-index: 200;
    }

    .k-grid-cancel, .k-grid-cancel-item {
        background: url('../../images/cancel-sprites.png') no-repeat top left transparent;
    }

    .k-grid-update {
        color: #fff;
        border-color: #177bba;
        background-color: #2198e3;
        background-image: none,linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(255,255,255,.2) 100%);
    }

    .btn-clear1 {
        background: url('../../images/clear-button.png') no-repeat top left;
        width: 28px;
        height: 26px;
        float: left;
        display: block;
        margin-top: 2px;

        &:hover {
            background-position: 0 -26px;
        }

        &:active {
            background-position: 0 -52px;
        }
    }
}
