.login-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    height: 52px;
    border-top: 1px solid #e42f2a;
    background: #fff;
    box-shadow: 0 10px 13px 0 #bbb;
    
    .company-logo {
        width: 134px;
        position: absolute;
        top: 1px;
        left: 0;
    }

    .customer-logo {
        max-height: 44px;
    }
}


@media only screen and (max-width: 700px) {
    .login-header {
        justify-content: flex-end;

        .customer-logo {
            max-width: 180px;
        }
    }
}