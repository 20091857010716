#incidentAlert {
    min-height: 400px;
    margin-top: 10px;

    .customEditLeft {
        display: inline;
        float: left;
        width: 40%;

        .k-edit-label {
            width: 28%;
        }

        .k-edit-field {
            width: 70%;
        }
    }

    .customEditRight {
        display: inline;
        float: left;
        width: 60%;

        .k-edit-label {
            width: 28%;
        }

        .k-edit-field {
            width: 70%;
            margin-top:4px;
        }

        input[type=checkbox] {
            margin-right: 9px;
            position: relative;
            top: 2px;
        }

        input[type=radio] {
            margin-right: 9px;
            position: relative;
            top: 2px;
        }

        .custom-edit-field {
            margin-right: 9px;
            position: relative;
            top: 2px;
        }
    }

    .Treebeard {
        overflow: auto;
        float: left;
        clear: both;
        border: solid 1px;
        border-color: lightgray;
        margin-left: 23px;
        min-height: 300px;
        max-height: 350px;
        width: 90%;
    }

    .TreebeardHeader {
        float: left;
        margin: 0 0 10px 24px;
    }
}
