ul.submenu {
    display: none;
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-position: outside;
    line-height: 30px;
    position: absolute;
    border: 1px solid #858585;
    -moz-box-shadow: 0 5px 10px #858585;
    -webkit-box-shadow: 0 5px 10px #858585;
    box-shadow: 0 5px 10px #858585;
    zoom: 1;

    &:hover {
        display: block;
    }

    li { /*submenu item*/
        position: relative;
        height: 30px;
        width: 240px;
        line-height: 30px;
        text-align: left;
        vertical-align: middle;
        border: 0 solid #fcfcfc;
        padding: 0;
        display: block;
        color: #444;
        background: #fcfcfc;
        cursor: pointer;
        text-shadow: none;
        font-size: 13px;
        font-weight: normal;
        text-transform: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-top: 1px solid #ccd2db;

        > a, > .mimic-link {
            display: block;
            color: #000;
            text-decoration: none;
            padding: 0 0 0 25px;
        }

        > .mimic-link {
            padding-top: 6px;
        }

        &[data-menu-id="Schedules"], &[data-menu-id="Services"], &[data-menu-id="Trips"] {
            border-top: 2px dashed black;
        }

        &.selected {
            background-color: #baffc4;

            &:hover {
                background-color: #baffc4;
            }
        }

        &:hover { /*when hover 2nd level menu*/
            background: #DCE1E7;

            & > ul { /* 3rd level menu open statis*/
                display: block;
            }
        }



        ul { /* 3rd level menu*/
            /* align 3rd level expands-to-right menu*/
            margin-left: 240px;
            margin-top: -30px;
            display: none;

            &:hover {
                /*when hover itself*/
                display: block;
            }
        }
        /*Show arrow for all list-item that has side menu inside*/
        &.has-side-menu {
            cursor: default;

            > a {
                pointer-events: none;
            }
            
            &:after {
                content: ' ';
                height: 0;
                position: absolute;
                width: 0;
                border: 4px solid transparent;
                border-left-color: #de0000;
                left: 220px;
                top: 10px;
            }
        }
        /*when submenu li has class child-left shift to leftside all submenu*/
        &.child-left > ul {
            left: -507px;
            right: 265px;
            top: 29px;
        }
    }
}
