.message-screen {
    width: 100%;
    height: 100%;
    display: flex;
    background: #f7f8fa;
    box-sizing: border-box;
    padding: 15px;


    h2 {
        margin: 0;
        font-family: Arial,Helvetica,sans-serif;
        color: #39639d;
        font-size: 16px;
        font-weight: normal;
    }

    .error {
        border: 2px solid red !important;
    }

    .left-column {
        width: 570px;
        margin-right: 15px;
        display: flex;
        flex-direction: column;

        .recipient-heading {
            height: 35px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .search-area {
                display: flex;
                align-items: center;

                .k-icon {
                    margin-right: 10px;
                }

                input {
                    border: 2px solid #9da2a6;
                    margin-right: 6px;
                    width: 188px;
                }
            }
        }

        .message-recipients {
            flex: 1;
            border: 1px solid #abadb3;
            overflow: auto;
            background: #fff;

            > ul {
                margin: 0;
                padding: 0;

                li {
                    margin: 0;
                    padding: 5px 10px;
                    cursor: pointer;

                    &:hover {
                        background: #e3f2f6;
                    }
                }
            }

            .k-treeview-lines {
                padding-left: 10px;
            }

            i {
                display: inline-block;
                width: 25px;
                height: 25px;
                background-repeat: no-repeat;
                margin-right: 7px;

                &.vehicleclass {
                    background-image: url('../../images/SendMessage/vehicle-group.png');
                }

                &.allroutes {
                    background-image: url('../../images/SendMessage/route-group.png');
                }

                &.alldispatchers {
                    background-image: url('../../images/SendMessage/user-group.png');
                }

                &.vehicle, &.dispatcher, &.route {
                    display: none;
                }
            }
        }
    }

    .right-column {
        flex: 1;
        display: flex;
        flex-direction: column;

        .right-column-top {
            background-color: #dfe5dd;
            border: 1px solid #a2a2a2;
            border-bottom-width: 0;
            border-radius: 8px 8px 0 0;
            padding: 10px;
            flex: 1;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;

            .recipient-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .current-recipients {
                height: 150px;
                border: 4px solid #a8d09c;
                background-color: #fff;
                margin-top: 5px;
                padding: 5px;
                font-size: 13px;
                color: #4e5256;
                overflow: auto;
            }

            .select-priority {
                display: flex;
                justify-content: space-between;
                margin: 10px 0;
                align-items: center;

                .priority-options {
                    background: linear-gradient(to bottom,#ccd7c9 0,#f4f4f4 100%);
                    border: 1px inset #ced1d5;
                    padding: 2px 18px;
                    border-radius: 10px;

                    .priority-label {
                        display: inline-block;
                        position: relative;
                        margin-right: 15px;
                        padding-left: 25px;
                        cursor: pointer;
                        // Hide default radio button
                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                        }
                        // Show custom radio button
                        .custom-radio {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 20px;
                            width: 20px;
                            background: url('../../images/SendMessage/radio-colored.png') no-repeat top left;
                        }

                        &.low {
                            input:checked ~ .custom-radio {
                                background-position-y: -60px;
                            }

                            &:hover {
                                input:checked ~ .custom-radio {
                                    background-position-y: -80px;
                                }
                            }

                            &:active {
                                input:checked ~ .custom-radio {
                                    background-position-y: -100px;
                                }
                            }
                        }

                        &.medium {
                            input:checked ~ .custom-radio {
                                background-position-y: -120px;
                            }

                            &:hover {
                                input:checked ~ .custom-radio {
                                    background-position-y: -140px;
                                }
                            }

                            &:active {
                                input:checked ~ .custom-radio {
                                    background-position-y: -160px;
                                }
                            }
                        }

                        &.high {
                            input:checked ~ .custom-radio {
                                background-position-y: -180px;
                            }

                            &:hover {
                                input:checked ~ .custom-radio {
                                    background-position-y: -200px;
                                }
                            }

                            &:active {
                                input:checked ~ .custom-radio {
                                    background-position-y: -220px;
                                }
                            }
                        }

                        &:hover {
                            input ~ .custom-radio {
                                background-position-y: -20px;
                            }
                        }

                        &:active {
                            input ~ .custom-radio {
                                background-position-y: -40px;
                            }
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            .message-textbox {
                flex: 1;
                display: flex;

                textarea {
                    width: 100%;
                    height: 100%;
                    resize: none;
                    flex: 1;
                    margin-right: 10px;
                }
            }

            .message-info {
                display: flex;
                justify-content: space-between;
                height: 30px;
                align-items: flex-end;
            }
        }

        .right-column-bottom {
            background: linear-gradient(to bottom,#8fc07f 0,#759e6e 100%);
            border: 1px solid #a2a2a2;
            border-bottom-color: #6c6c6c;
            border-radius: 0 0 50px 50px;
            padding: 7px 40px;
            display: flex;
            justify-content: space-between;

            button {
                padding: 12px 40px;
                border-radius: 25px;
                color: #fff;
                text-decoration: none;
                border: 0;
                font-size: 16px;
                cursor: pointer;
                outline: none;
            }

            .cancel-button {
                background: linear-gradient(to bottom,#94969a 0,#4d4e4e 100%);

                &:hover {
                    background: linear-gradient(to bottom,#bebebe 0,#525252 100%);
                }

                &:active {
                    background: linear-gradient(to bottom,#000 0,#2d2d2d 100%);
                }
            }

            .send-button {
                background: linear-gradient(to bottom,#5d5d5d 0,#070707 100%);
                font-weight: bold;

                &:hover {
                    background: linear-gradient(to bottom,#000 0,#2d2d2d 100%);
                }
            }
        }
    }

    #messageCollectionToggle {
        display: block;
        float: left;
        padding: 10px;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#fdfdfd', endColorStr='#cfd5da');
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#fdfdfd', endColorstr='#cfd5da')";
        background: -webkit-gradient(linear, left top, left bottom, from(#fdfdfd), to(#cfd5da));
        background: -moz-linear-gradient(top, #fdfdfd, #cfd5da);
        background: linear-gradient(to bottom,#fdfdfd 0,#cfd5da 100%);
        border: 1px solid #a1acb7;
        border-radius: 4px;

        .down-arrow {
            display: block;
            background: url('../../images/kendo-sprite.png') no-repeat -5px -166px;
            width: 6px;
            height: 4px;
        }

        &:hover {
            filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#fdfdfd', endColorStr='#a7cae6');
            -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#fdfdfd', endColorstr='#a7cae6')";
            background: -webkit-gradient(linear, left top, left bottom, from(#fdfdfd), to(#a7cae6));
            background: -moz-linear-gradient(top, #fdfdfd, #a7cae6);
            background: linear-gradient(to bottom,#fdfdfd 0,#a7cae6 100%);
        }

        &:active, &.pressed {
            filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#fdfdfd', endColorStr='#98c0e1');
            -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#fdfdfd', endColorstr='#98c0e1')";
            background: -webkit-gradient(linear, left top, left bottom, from(#fdfdfd), to(#98c0e1));
            background: -moz-linear-gradient(top, #fdfdfd, #98c0e1);
            background: linear-gradient(to bottom,#fdfdfd 0,#98c0e1 100%);
            border: 1px solid #729cc0;
        }
    }
}

#cannedMessage {
    .k-animation-container {
        z-index: 99999 !important;
    }

    #messageCollectionHolder {
        position: absolute;
        z-index: 9999;
        padding: 1px;
        right: 0px;
        border: 1px solid #7d92a2;
        border-radius: 2px;
        background-color: #fff;

        .k-treeview {
            border: 1px solid #000;
            border-radius: 2px;
            padding: 5px;
            width: 300px;
            max-height: 280px;
            overflow: auto;
            font-size: 16px;
            font-family: Arial,Helvetica,sans-serif;

            ul {
                padding-left: 5px;
                margin-top: 0px;
            }

            .k-state-selected, .k-state-focused, .k-state-hover {
                border-color: #fff;
                color: inherit;
                background-color: inherit;
                box-shadow: none;
                background-image: none;
            }

            ul > li > ul {
                .k-state-hover, .k-in:hover {
                    background-color: #dceef4;
                    border: 1px solid #b5dee8;
                    cursor: pointer;
                }
            }
        }
    }
}
