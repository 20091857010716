.map-holder {
    height: 100%;

    .aerial-toggle {
        position: absolute;
        background: #fff;
        width: 125px;
        height: 50px;
        right: 20px;
        margin-top: 10px;
        z-index: 20;
        border-radius: 10px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
        cursor: pointer;

        .aerial-label {
            font-size: 18px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 5px;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 50%;
            right: 5px;
            width: 60px;
            height: 34px !important;
            transform: translateY(-50%);
            display: block;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 34px;

            &.on {
                background-color: #2196F3;

                &:before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                }
            }

            &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 50%;
            }
        }
    }

    .infoBox, .leaflet-popup-content-wrapper {
        min-width: 250px !important;
        min-height: 20px !important;
        background-color: #fff !important;
        border-radius: unset !important;
        box-shadow: 0px 0px 4px #7e7e7e !important;
        font-family: Calibri !important;
        font-size: 14px !important;
        white-space: pre-wrap;
    }

    .leaflet-container {
        height: 100%;
        z-index: 1;

        a.leaflet-popup-close-button {
            right: 7px !important;
        }
    }

    .leaflet-popup-content {
        max-height: 500px;
        margin: 25px 10px 13px 10px !important;
        overflow: auto;
    }
}
