#ss-content .divContent {
    padding: 5px;
    font-size: 15px !important;
    text-align: center;
    z-index: 99999;
}

.btnContent{
    margin-left : 15%;
}

#ss-content .k-button.sd-button {
    width: 100%;
    padding: 2px;
    margin-bottom: 5px;
    margin-top: 5px;    
}

#ss-content .k-button.nav-button {
    width: 75px;
    padding: 2px;
    margin: 5px;
    display: inline-block;
}

#ss-content .k-button.nav-button {
    width: 75px;
    padding: 2px;
    margin: 5px;
    display: inline-block;
}

.dropdownListItem{
    font-size : 13px !important;
    font-style:italic;    
}

.dropdownBcg {
    background-color: white;
}


