.inline-message {
    height:30px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 20px;
    text-align:center;
    margin-left: 6px;
    margin-right: 6px;
    position: relative;
    top: 10px;
    padding: 2px 4px 2px 30px;
    border: 2px solid #61ac11;
    background: url('../../images/hourglass.gif') no-repeat 2px 0;
}
