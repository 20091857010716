#alertInbox {
    height: 100%;
    /*.k-window-content {
        padding: 10px 16px !important;
    }

    .k-dialog-content {
        padding: 10px 16px !important;
    }*/

    .smi-grid {
        height: 100%;

        .smi-grid-search {
            margin-left: unset;
            order: -3;
        }

        .icon-trashbin {
            color: #000;
            font-weight: bold;
            border: 1px solid #bebfc3;
            -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#e9edf0', endColorstr='#ced1d6')";
            filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#e9edf0', endColorStr='#ced1d6');
            background: -webkit-gradient(linear, left top, left bottom, from(#e9edf0), to(#ced1d6));
            background: -moz-linear-gradient(top, #e9edf0, #ced1d6);
            background: linear-gradient(to bottom,#e9edf0 0,#ced1d6 100%);
            margin: 0 0.48em;
            -webkit-transition: background 0.2s ease;
            -moz-transition: background 0.2s ease;
            -o-transition: background 0.2s ease;
            transition: background 0.2s ease;
        }


        .btn-clear {
            border-radius: 2px;
            padding: 4px 8px;
            box-sizing: border-box;
            border-width: 1px;
            border-style: solid;
            font-size: 11pt;
            font-weight: bold;
            line-height: 1.85;
            font-family: inherit;
            text-align: center;
            text-decoration: none;
            white-space: nowrap;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            grid-gap: 4px;
            gap: 4px;
            vertical-align: middle;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;
            outline: none;
            -webkit-appearance: none;
            position: relative;
            order: -1;

            .k-icon {
                font-size: 28px;
            }

            &:hover {
                color: white;
                background: #77A820;

                .k-i-delete {
                    color: black;
                }
            }
        }

        .btn-read {
            border-radius: 2px;
            padding: 4px 8px;
            box-sizing: border-box;
            border-width: 1px;
            border-style: solid;
            font-family: inherit;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;
            outline: none;
            -webkit-appearance: none;
            position: relative;

            &:hover {
                color: white;
                background: #77A820;
            }
        }

        .read {
            color: #999;
        }

        .inline-success, .inline-error {
            order: -2;
            margin: auto;
        }

        .show-read {
            order: -2;
            margin-right: auto;
            margin-left: 15px;
            line-height: 30px;
            font-size: 16px;
        }

        .smi-action-column button {
            width: auto;
        }

        .disabled {
            opacity: 0.6;
            pointer-events: none;
        }

        .k-grid-Position {
            height: 25px;
            padding: 0 0 0 25px;
            width: 0;
            min-width: 0;
            border: none;
            box-shadow: none;
            overflow: hidden;
            display: block;
            margin: 0 auto;
            background: url('../../images/map-sprites-temp.png') no-repeat top left transparent;

            &:hover {
                background-position: 0 -25px;
            }

            &:active {
                background-position: 0 -50px;
            }

            .disabled {
                pointer-events: none;
                opacity: 0.7;
            }
        }

        .k-grid .k-command-cell, .k-grid .k-edit-cell, .k-grid .k-grid-edit-row td:not(.k-hierarchy-cell){
            padding:8px 12px !important;
        }
    }

    .inboxMapPushpin {
        width: 24px;
        height: 24px;
        text-align: center;
        color: #fff;
        background: url('../../images/vehicle.png') no-repeat;
    }

    .bunch {
        width: 25px;
        height: 24px;
        background: url('../../images/vehicle_group.png') no-repeat;
    }

    #alertMap {
        .k-dialog-content {
            padding: 0px;
        }
    }
}

.k-animation-container, k-animation-container-relative, k-animation-container-shown {
    z-index: 999990000 !important;
}
