.recipient-presentation {
    height: 20px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    padding-left: 18px;
    background-repeat: no-repeat;
    background-position-y: 3px;

    &.recipient-vehicleclass {
        background-image: url('../../images/SendMessage/recipient-vehicle-group.png');
    }

    &.recipient-vehicle {
        background-image: url('../../images/SendMessage/recipient-vehicle.png');
    }

    &.recipient-dispatcher {
        background-image: url('../../images/SendMessage/recipient-user.png');
    }

    &.recipient-alldispatchers {
        background-image: url('../../images/SendMessage/recipient-user-group.png');
    }

    &.recipient-route {
        background-image: url('../../images/SendMessage/recipient-route.png');
    }

    &.recipient-allroutes {
        background-image: url('../../images/SendMessage/recipient-route-group.png');
    }

    button {
        border: 0;
        background: none;
        outline: none;
        cursor: pointer;
        position: relative;
        top: 1px;

        svg circle {
            fill: #a6abb2;
        }

        &:hover {
            svg circle {
                fill: #cb120d;
            }
        }
    }
}
