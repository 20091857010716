.customEditor {
    min-height: 300px;
    max-height: 600px;
    overflow: auto;
    font-family: 'Segoe UI', sans-serif;
    font-size: 13px;
    padding-top: 10px;
    position: relative;

    .form-control {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;

        .k-edit-label {
            width: 25%;
            margin: 0px 10px 0px 0px;
            padding: 0px;
        }

        .k-edit-field {
            width: 65%;
            margin-top:-2px;

            ul {
                list-style-type: none;
                height: 105px;
                overflow: auto;
                width: 350px;
                padding: 0;

                li {
                    display: block;
                    float: left;
                    width: 160px;
                }
            }
        }

        .alert-checkbox {
            display: flex;
            justify-content: space-around;
        }
        .numeric-textbox{
            margin: 0 5px;
        }

        .routes {
            height: 130px;
            overflow: auto;
        }


        input[type=checkbox], input[type=radio] {
            margin-right: 9px !important;
            position: relative;
            top: 2px;
        }
    }

    .normal-alert-dropdown {
        width: 250px !important;
    }

    .k-popup{
        min-width: 250px !important;
    }
}
