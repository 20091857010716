.smi-action-column {
    button {
        height: 25px;
        width: 25px;
        border: none;
        box-shadow: none;
        overflow: hidden;
        display: block;
        margin: 0 auto;
        background-position: top left;
        background-repeat: no-repeat;

        &:hover {
            background-position: 0 -25px;
        }

        &:active {
            background-position: 0 -50px;
        }

        .k-icon {
            display: none;
        }

        &.disabled {
            background-position: top left !important;
            opacity: 0.5;
            pointer-events: none;
        }

        &.k-grid-login, &.k-grid-forward {
            background-image: url('../../images/forward-sprites.png');
        }

        &.k-grid-edit {
            background-image: url('../../images/edit-sprites.png');
        }

        &.k-grid-view {
            background-image: url('../../images/view-sprites.png');
        }

        &.k-grid-view-rpt {
            background-image: url('../../images/view-rpt-sprites.png');
        }

        &.k-grid-email {
            background-image: url('../../images/email-sprites.png');
        }

        &.k-grid-update {
            background-image: url('../../images/update-sprites.png');
        }

        &.k-grid-cancel, .k-grid-cancel-item {
            background-image: url('../../images/cancel-sprites.png');
        }

        &.k-grid-forward, .k-grid-duplicate, .k-grid-reply, .k-grid-login {
            background-image: url('../../images/forward-sprites.png');
        }

        &.k-grid-history {
            background-image: url('../../images/history-sprites.png');
        }

        &.k-grid-replace {
            background-image: url('../../images/replace-sprites.png');
        }

        &.k-grid-call {
            background-image: url('../../images/call-sprites.png');
        }

        &.k-grid-split {
            background-image: url('../../images/split-sprites.png');
        }

        &.k-grid-play {
            background-image: url('../../images/play-sprites.png');
        }

        &.k-grid-delete {
            background-image: url('../../images/delete-sprites.png');
        }
    }
}

.k-grid-edit-row .smi-action-column button {
    height: 25px;
}