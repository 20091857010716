.k-window {
    .k-window-titlebar {
        background-color: #43464A;
        padding: 6px 0 6px 8px;
        cursor: move;
        box-sizing: border-box;
        position: relative;

        .k-window-title {
            font-size: 1.2em;
            cursor: move;
        }

        button:not(:last-child) {
            display: none;
        }

        button:last-child {
            background: linear-gradient(to bottom, #fd2114 0%, #cd0000 50%, #7d0000 100%) !important;
            height: 100%;
            position: relative;
            border: 0;
            border-radius: 0;

            .k-i-close {
                font-size: 26px;
                text-shadow: 0 0 3px #000;
                position: absolute;
            }
        }

        .k-dialog-close {
            background: linear-gradient(to bottom, #fd2114 0%, #cd0000 50%, #7d0000 100%) !important;
            height: 100%;
            position: absolute;
            border: 0;
            border-radius: 0;
            right: 0;
            top: 0;

            .k-icon {
                font-size: 26px;
                text-shadow: 0 0 3px #000;
                position: absolute;
            }
        }
    }

    .k-window-content {
        overflow: hidden;
    }
}

.k-loading-mask {
    .k-loading-image {
        background: url('https://kendo.cdn.telerik.com/2018.2.620/styles/Silver/loading-image.gif') no-repeat center center;

        &::before, &::after {
            border: 0 !important;
        }
    }
}

.k-widget.k-splitter {
    background-color: transparent;
}

.k-toolbar, .k-grid-toolbar, .silverlight-button-zone {
    .k-button {
        color: #000;
        font-weight: bold;
        border: 1px solid #bebfc3;
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#e9edf0', endColorstr='#ced1d6')";
        filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#e9edf0', endColorStr='#ced1d6');
        background: -webkit-gradient(linear, left top, left bottom, from(#e9edf0), to(#ced1d6));
        background: -moz-linear-gradient(top, #e9edf0, #ced1d6);
        background: linear-gradient(to bottom,#e9edf0 0,#ced1d6 100%);
        margin: 0 0.48em;
        -webkit-transition: background 0.2s ease;
        -moz-transition: background 0.2s ease;
        -o-transition: background 0.2s ease;
        transition: background 0.2s ease;

        .k-icon {
            background: url('../images/grid-button-sprites.png') no-repeat;
            width: 28px;
            height: 26px;

            &.k-add, &.k-i-add, &.k-i-plus {
                background-position: -28px 0;
            }

            &.k-i-custom {
                background-position: -84px 0;
            }

            &.k-cancel, &.k-i-cancel {
                background-position: -56px 0;
            }

            &.k-expand, &.k-i-expand {
                background-position: -112px 0;
            }

            &.k-contract, &.k-i-contract {
                background-position: -140px 0;
            }

            &.k-up, &.k-i-up {
                background-position: -168px 0;
            }

            &.k-down, &.k-i-down {
                background-position: -196px 0;
            }

            &.k-magnify, &.k-i-magnify {
                background-position: -224px 0;
            }

            &.k-undo, &.k-i-undo {
                background-position: -252px 0;
            }

            &.k-refresh, &.k-i-refresh {
                background-position: -280px 0;
            }

            &.k-copy, &.k-i-copy {
                background-position: -308px 0;
            }

            &.k-send, &.k-i-send {
                background-position: -336px 0;
            }

            &.k-send-command, &.k-i-send-command {
                background-position: -364px 0;
            }

            &.k-settings, &.k-i-settings {
                background-position: -392px 0;
            }

            &.k-edit, &.k-i-edit {
                background-position: -420px 0;
            }

            &::before {
                content: none;
            }
        }

        &.k-grid-save-changes {
            .k-icon {
                margin-right: 15px;
                background-position: 0;
            }

            padding-right: 20px;
        }

        &.disabled {
            cursor: default;
            opacity: 0.6;
            -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#e9edf0', endColorstr='#ced1d6')" !important;
            filter: alpha(opacity=60), progid:DXImageTransform.Microsoft.gradient(startColorStr='#e9edf0', endColorStr='#ced1d6') !important;
            background: -webkit-gradient(linear, left top, left bottom, from(#e9edf0), to(#ced1d6)) !important;
            background: -moz-linear-gradient(top, #e9edf0, #ced1d6) !important;
            background: linear-gradient(to bottom,#e9edf0 0,#ced1d6 100%) !important;
            -moz-transition: none;
            -o-transition: none;
            -webkit-transition: none;
            transition: none;

            &:hover {
                color: #000;
                background-color: #CED1D6;
                border-color: #BEBFC3;
            }

            &:focus {
                box-shadow: none;
                color: #000;
                background-color: #CED1D6;
                border-color: #BEBFC3;
            }

            &:active {
                color: #000;
                background-color: #CED1D6;
                border-color: #BEBFC3;
            }

            .k-icon {
                background-image: url('../images/grid-button-sprites-grey.png');
            }
        }

        &:hover {
            color: #fff;
            background: #77A820;
        }

        &:active {
            border-color: #BEBFC3;
        }

        &.clicked {
            background: #2d6eca;
            color: #fff;
        }
    }
}

body .k-dialog-wrapper {
    z-index: 9999999;
}

.k-button.disabled {
    opacity: 0.6;
    pointer-events: none;
}
/* This style is a hack to hide the close button on kendo windows/dialogs, which kendo doesn't provide a way to hide.
It can be invoked by setting style={{animationName:'hide-close'}} on the Window or Dialog component */
.k-window[style~=hide-close\;], .k-dialog-wrapper[style~=hide-close\;] {
    .k-button-icon {
        display: none;
    }
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0,20px,0);
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.k-window.k-dialog {
    opacity: 0;
    animation-name: fadeInUp;
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.k-calendar-container, .k-list-container {
    z-index: 9999999; //This style is needed to ensure the calendar renders above the overlay when datepickers are placed in dialog components
}