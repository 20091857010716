.hardware-registration-page {

    a {
        text-decoration: none;
        color: blue;
        margin-left: 10px;
    }

    textarea {
        width: 95%;
        margin: 0 auto;
        border: 2px solid #6387b7;
        height: 140px;
        padding: 5px;
    }

    h1 {
        text-align: center;
        font-size: 1.5em;
        text-transform: capitalize;
    }

    fieldset {
        text-align: center;
        width: 80%;
        margin: 15px auto;
    }

    .save-area {
        text-align: center;    
    }

    select {
        width: 95%;
        margin: 0 auto;
        padding: 4px;
    }

    a.save-button {
        display: inline-block;
        background-image: url('../../images/btn_sprite.png');
        background-position: 0 0;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        width: 202px;
        height: 31px;
        padding-top: 7px;
        text-shadow: 0 -2px rgba(0,0,0,0.3);
        cursor: pointer;
    }
    a.save-button.clicked {
            background-position: 0 -114px
    }

}