.message-screen {
    .announcement-holder {
        display: flex;
        flex-direction: column;
        flex: 1;

        button {
            margin-left: 15px;
        }

        h2 {
            margin: 7px 0;
        }

        textarea {
            flex: 1;
            border: 2px solid #a8d09c;
            border-radius: 2px;
        }
    }
}
