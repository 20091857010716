body {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    /*background: linear-gradient(to bottom, #dee3e9 0%,#ffffff 48%,#b2bac1 100%);*/
    font-family: 'Segoe UI', sans-serif;
    font-size: 13px;
    overflow-y:hidden;
}

iframe {
    border: 0px;
}

.clear {
    clear: both;
}

.normal-green-button {
    background-color: #4CAF50;
    border: none;
    border-radius: 3px 4px;
    color: white;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 5px;
    cursor: pointer;
}

.normal-gray-button {
    background-color: #606c88;
    border: none;
    border-radius:3px 4px;
    color: white;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 5px;
    cursor: pointer;
}

    .normal-gray-button:hover {
        background-color: #737c91;
    }
/* Anchor Button */
.green-btn {
    box-sizing: border-box;
    display: inline-block;
    background-image: url(./images/btn_runreports_sprite.png);
    background-position: 0 0;
    color: white !important;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    border: 0;
    outline: none;
    width: 202px;
    height: 38px;
    padding-top: 7px;
    text-shadow: 0 -2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

    .green-btn:hover {
        background-position: 0 -38px;
    }
    .green-btn.disabled,
    .green-btn[disabled] {
        opacity: 0.6;
        filter: alpha(opacity=60);
        cursor: default;
    }
    /* End of  Anchor Button */
    .go-button {
        background-image: url('images/highlight.png');
        background-image: none, -webkit-linear-gradient(top, #b7f34b 0%, #568505 100%);
        background-image: none, -moz-linear-gradient(top, #b7f34b 0%, #568505 100%);
        background-image: none, -o-linear-gradient(top, #b7f34b 0%, #568505 100%);
        background-image: none, linear-gradient(to bottom, #b7f34b 0%, #568505 100%);
        background-color: #7ab906;
        display: inline-block;
        width: 195px;
        height: 32px;
        border: 1.2px solid #e9edf0;
        /*border-top:1px solid #a0d904;
    border-bottom: 1px solid #659907;
    border-left: 1px solid #77b210;
    border-right: 1px solid #77b210;*/
        box-shadow: 0px -0.05px 0px 1px #5a5761;
        font-size: 18px;
        text-transform: uppercase;
        cursor: pointer;
        text-shadow: 0px 0px 2px #222222;
        text-align: center;
        border-radius: 4px;
        color: white;
        -webkit-transition: opacity 0.2s ease;
        -moz-transition: opacity 0.2s ease;
        -o-transition: opacity 0.2s ease;
        transition: opacity 0.2s ease;
    }

    .go-button:hover {
        opacity: 0.8;
    }

.background-gradient {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#ffffff', endColorStr='#b1b9c0');
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ffffff', endColorstr='#b1b9c0')";
    background: -webkit-gradient(linear, left top, left bottom, from(white), to(#b1b9c0));
    background: -moz-linear-gradient(top, white, #b1b9c0);
    background: linear-gradient(to bottom, white 0, #dde2e8 9%, #d5dbe1 31%, #c9d0d6 54%, #bdc4cb 77%, #b1b9c0 100%);
}

.btn-grad-green {
    background-image: linear-gradient(to right, #457fca 0%, #5691c8 51%, #457fca 100%)
}

.btn-grad-green {
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
}

    .btn-grad-green:hover {
        background-position: right center; /* change the direction of the change here */
        color: #fff;
        text-decoration: none;
    }



.btn-grad-gray {
    background-image: linear-gradient(to right, #606c88 0%, #3f4c6b 51%, #606c88 100%)
}

.btn-grad-gray {
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
}

    .btn-grad-gray:hover {
        background-position: right center; /* change the direction of the change here */
        color: #fff;
        text-decoration: none;
    }
         
/*button.green {
    background: linear-gradient(to bottom, #d2eba6 0%, #91cb2b 50%, #76b20b 100%);
    color: #fff;
    text-shadow: 0 -2px rgba(0, 0, 0, 0.3);
    font-size: 20px;
    height: 33px;
    border: 1px solid #747679;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Segoe UI', sans-serif;
}

    button.green:hover {
        background: linear-gradient(to bottom, #cee3ab 0%, #99c84a 50%, #99c84a 100%);
    }

    button.green:active {
        background: linear-gradient(to bottom, #8aaa5c 0%, #679628 50%, #679628 100%);
    }

    button.green.clicked {
        background: linear-gradient(to bottom, #1d62c1 0%, #3b79d3 50%, #3b79d3 100%);
    }*/

button.mimic-link {
    text-decoration: underline;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    outline: none;
}

button.clear-btn {
    background: url('images/clear-button.png') no-repeat top left;
    width: 28px;
    height: 26px;
    border: 0;
    outline: none;
}

    button.clear-btn:hover {
        background-position: 0 -26px;
    }

    button.clear-btn:active {
        background-position: 0 -52px;
    }

button.green {
    display: inline-block;
    background-image: url(./images/btn_sprite.png);
    background-position: 0 0;
    background-size: cover;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    width: 202px;
    height: 38px;
    padding-top: 7px;
    text-shadow: 0 -2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border: 0;
}

    button.green:hover {
        background-position: 0 -38px;
    }

    button.green:active {
        background-position: 0 -76px;
    }

    button.green.clicked {
        background-position: 0 -114px;
        cursor: default;
    }

    button.green.disabled,
    button.green[disabled] {
        opacity: 0.6;
        filter: alpha(opacity=60);
        cursor: default;
    }

        button.green.disabled:hover,
        button.green[disabled]:hover,
        button.green.disabled:active,
        button.green[disabled]:active {
            background-position: top left;
        }

.hide {
    display: none !important;
}
/*button.green {
    height: 38px;
    border: 0;
}*/

.search-query {
    padding: 5px;
    border: 2px solid #9da2a6;
    border-radius: 2px;
    margin-right: 9px;
}

.search-button {
    display: inline-block;
    width: 66px;
    height: 30px;
    background: url('./images/search-button.png') no-repeat top left;
    float: right;
}

    .search-button:hover {
        background-position: 0 -31px;
    }

    .search-button:active {
        background-position: 0 -62px;
    }

.inline-error,
.inline-success {
    display: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    margin-left: 6px;
    position: relative;
    top: 3px;
    padding: 2px 4px 2px 30px;
}

.inline-error {
    border: 2px solid #ca1911;
    background: url('./images/alerts_save_error.png') no-repeat 2px 2px;
}

.inline-success {
    border: 2px solid #61ac11;
    background: url('./images/alerts_save_success.png') no-repeat 2px 2px;
}

.k-grid.k-widget td .k-grid-edit {
    background: url('./images/edit-sprites.png') no-repeat top left transparent;
    height: 25px;
    width: 25px;
    border: 0px;
}

.k-grid.k-widget td .k-grid-view {
    background: url('./images/view-sprites.png') no-repeat top left transparent;
    height: 25px;
    width: 25px;
    border: 0px;
}

.k-grid.k-widget td .k-grid-view-rpt {
    background: url('./images/view-rpt-sprites.png') no-repeat top left transparent;
    height: 25px;
    width: 25px;
    border: 0px;
}

.k-grid.k-widget td .k-grid-email {
    background: url('./images/email-sprites.png') no-repeat top left transparent;
    height: 25px;
    width: 25px;
    border: 0px;
}

.k-grid.k-widget td .k-grid-update {
    background: url('./images/update-sprites.png') no-repeat top left transparent;
    height: 25px;
    width: 25px;
    border: 0px;
}

/**** Grid Button ******/
.k-toolbar,
.k-grid-toolbar {
    background-color: #f6f7f9;
    padding-bottom: 20px;
}

.k-dropdown .k-dropdown-wrap .k-input::before {
    width: 0;
}

.k-toolbar .k-button,
.k-grid-toolbar .k-button,
.silverlight-button-zone .k-button {
    color: #000;
    font-weight: bold;
    border: 1px solid #bebfc3;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#e9edf0', endColorstr='#ced1d6')";
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#e9edf0', endColorStr='#ced1d6');
    background: -webkit-gradient(linear, left top, left bottom, from(#e9edf0), to(#ced1d6));
    background: -moz-linear-gradient(top, #e9edf0, #ced1d6);
    background: linear-gradient(to bottom, #e9edf0 0, #ced1d6 100%);
    margin: 0 0.48em;
    -webkit-transition: background 0.2s ease;
    -moz-transition: background 0.2s ease;
    -o-transition: background 0.2s ease;
    transition: background 0.2s ease;
}

    .k-toolbar .k-button .k-icon,
    .k-grid-toolbar .k-button .k-icon,
    .silverlight-button-zone .k-button .k-icon {
        background: url('./images/grid-button-sprites.png') no-repeat;
        width: 28px;
        height: 26px;
    }

        .k-toolbar .k-button .k-icon.k-add,
        .k-grid-toolbar .k-button .k-icon.k-add,
        .silverlight-button-zone .k-button .k-icon.k-add,
        .k-toolbar .k-button .k-icon.k-i-add,
        .k-grid-toolbar .k-button .k-icon.k-i-add,
        .silverlight-button-zone .k-button .k-icon.k-i-add,
        .k-toolbar .k-button .k-icon.k-i-plus,
        .k-grid-toolbar .k-button .k-icon.k-i-plus,
        .silverlight-button-zone .k-button .k-icon.k-i-plus {
            background-position: -28px 0;
        }

        .k-toolbar .k-button .k-icon.k-i-custom,
        .k-grid-toolbar .k-button .k-icon.k-i-custom,
        .silverlight-button-zone .k-button .k-icon.k-i-custom {
            background-position: -84px 0;
        }

        .k-toolbar .k-button .k-icon.k-cancel,
        .k-grid-toolbar .k-button .k-icon.k-cancel,
        .silverlight-button-zone .k-button .k-icon.k-cancel,
        .k-toolbar .k-button .k-icon.k-i-cancel,
        .k-grid-toolbar .k-button .k-icon.k-i-cancel,
        .silverlight-button-zone .k-button .k-icon.k-i-cancel {
            background-position: -56px 0;
        }

        .k-toolbar .k-button .k-icon.k-expand,
        .k-grid-toolbar .k-button .k-icon.k-expand,
        .silverlight-button-zone .k-button .k-icon.k-expand,
        .k-toolbar .k-button .k-icon.k-i-expand,
        .k-grid-toolbar .k-button .k-icon.k-i-expand,
        .silverlight-button-zone .k-button .k-icon.k-i-expand {
            background-position: -112px 0;
        }

        .k-toolbar .k-button .k-icon.k-contract,
        .k-grid-toolbar .k-button .k-icon.k-contract,
        .silverlight-button-zone .k-button .k-icon.k-contract,
        .k-toolbar .k-button .k-icon.k-i-contract,
        .k-grid-toolbar .k-button .k-icon.k-i-contract,
        .silverlight-button-zone .k-button .k-icon.k-i-contract {
            background-position: -140px 0;
        }

        .k-toolbar .k-button .k-icon.k-up,
        .k-grid-toolbar .k-button .k-icon.k-up,
        .silverlight-button-zone .k-button .k-icon.k-up,
        .k-toolbar .k-button .k-icon.k-i-up,
        .k-grid-toolbar .k-button .k-icon.k-i-up,
        .silverlight-button-zone .k-button .k-icon.k-i-up {
            background-position: -168px 0;
        }

        .k-toolbar .k-button .k-icon.k-down,
        .k-grid-toolbar .k-button .k-icon.k-down,
        .silverlight-button-zone .k-button .k-icon.k-down,
        .k-toolbar .k-button .k-icon.k-i-down,
        .k-grid-toolbar .k-button .k-icon.k-i-down,
        .silverlight-button-zone .k-button .k-icon.k-i-down {
            background-position: -196px 0;
        }

        .k-toolbar .k-button .k-icon.k-magnify,
        .k-grid-toolbar .k-button .k-icon.k-magnify,
        .silverlight-button-zone .k-button .k-icon.k-magnify,
        .k-toolbar .k-button .k-icon.k-i-magnify,
        .k-grid-toolbar .k-button .k-icon.k-i-magnify,
        .silverlight-button-zone .k-button .k-icon.k-i-magnify {
            background-position: -224px 0;
        }

        .k-toolbar .k-button .k-icon.k-undo,
        .k-grid-toolbar .k-button .k-icon.k-undo,
        .silverlight-button-zone .k-button .k-icon.k-undo,
        .k-toolbar .k-button .k-icon.k-i-undo,
        .k-grid-toolbar .k-button .k-icon.k-i-undo,
        .silverlight-button-zone .k-button .k-icon.k-i-undo {
            background-position: -252px 0;
        }

        .k-toolbar .k-button .k-icon.k-refresh,
        .k-grid-toolbar .k-button .k-icon.k-refresh,
        .silverlight-button-zone .k-button .k-icon.k-refresh,
        .k-toolbar .k-button .k-icon.k-i-refresh,
        .k-grid-toolbar .k-button .k-icon.k-i-refresh,
        .silverlight-button-zone .k-button .k-icon.k-i-refresh {
            background-position: -280px 0;
        }

        .k-toolbar .k-button .k-icon.k-copy,
        .k-grid-toolbar .k-button .k-icon.k-copy,
        .silverlight-button-zone .k-button .k-icon.k-copy,
        .k-toolbar .k-button .k-icon.k-i-copy,
        .k-grid-toolbar .k-button .k-icon.k-i-copy,
        .silverlight-button-zone .k-button .k-icon.k-i-copy {
            background-position: -308px 0;
        }

        .k-toolbar .k-button .k-icon.k-send,
        .k-grid-toolbar .k-button .k-icon.k-send,
        .silverlight-button-zone .k-button .k-icon.k-send,
        .k-toolbar .k-button .k-icon.k-i-send,
        .k-grid-toolbar .k-button .k-icon.k-i-send,
        .silverlight-button-zone .k-button .k-icon.k-i-send {
            background-position: -336px 0;
        }

        .k-toolbar .k-button .k-icon.k-send-command,
        .k-grid-toolbar .k-button .k-icon.k-send-command,
        .silverlight-button-zone .k-button .k-icon.k-send-command,
        .k-toolbar .k-button .k-icon.k-i-send-command,
        .k-grid-toolbar .k-button .k-icon.k-i-send-command,
        .silverlight-button-zone .k-button .k-icon.k-i-send-command {
            background-position: -364px 0;
        }

        .k-toolbar .k-button .k-icon.k-settings,
        .k-grid-toolbar .k-button .k-icon.k-settings,
        .silverlight-button-zone .k-button .k-icon.k-settings,
        .k-toolbar .k-button .k-icon.k-i-settings,
        .k-grid-toolbar .k-button .k-icon.k-i-settings,
        .silverlight-button-zone .k-button .k-icon.k-i-settings {
            background-position: -392px 0;
        }

        .k-toolbar .k-button .k-icon.k-edit,
        .k-grid-toolbar .k-button .k-icon.k-edit,
        .silverlight-button-zone .k-button .k-icon.k-edit,
        .k-toolbar .k-button .k-icon.k-i-edit,
        .k-grid-toolbar .k-button .k-icon.k-i-edit,
        .silverlight-button-zone .k-button .k-icon.k-i-edit {
            background-position: -420px 0;
        }

        .k-toolbar .k-button .k-icon::before,
        .k-grid-toolbar .k-button .k-icon::before,
        .silverlight-button-zone .k-button .k-icon::before {
            content: none;
        }

    .k-toolbar .k-button.k-grid-save-changes,
    .k-grid-toolbar .k-button.k-grid-save-changes,
    .silverlight-button-zone .k-button.k-grid-save-changes {
        padding-right: 20px;
    }

        .k-toolbar .k-button.k-grid-save-changes .k-icon,
        .k-grid-toolbar .k-button.k-grid-save-changes .k-icon,
        .silverlight-button-zone .k-button.k-grid-save-changes .k-icon {
            margin-right: 15px;
            background-position: 0;
        }

/**** Grid Button *****/


/******* Kendo Dropdownlist*/

/*Global dropdown list item z-index*/
.k-animation-container {
    z-index: 10003;
}

/*.k-list {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    white-space: nowrap;
}*/

/*.k-list-container {
    width: inherit !important;    
}*/

.k-popup .k-list .k-item {
    white-space: nowrap;
    padding-right: 25px;
}
/***************************/

/*************** Loading *********************/
.loading-overlay {
    position: absolute;
    background-color: #ccc;
    opacity: 0.5;
    filter: alpha(opacity=50);
    z-index: 999999;
    display: none;
    width: 100%;
    height: 100%;
}

    .loading-overlay.full-page {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .loading-overlay .img {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 48px;
        height: 48px;
        margin-top: -24px;
        margin-left: -24px;
        background-image: url(images/loading-image.gif);
        z-index: 99999;
    }


.loading .loading-overlay {
    display: block;
}

.gridHelper-loading .loading-overlay {
    display: block;
}
