.page-wrapper {
    height: calc(100% - 20px);
    padding: 10px;

    .title-holder {
        height: 100%;
        background-color: #404348;
        border-radius: 25px 25px 10px 10px;

        .title {
            font-size: 18px;
            color: white;
            font-weight: bold;
            text-shadow: 0px 1px 2px black;
            margin: 0;
            padding: 10px 18px;
            display: flex;
            align-items: center;

            img {
                height: 30px;
                image-rendering: crisp-edges;
                position: relative;
                left: -5px;
                top: 1px;
            }

            > span {
                margin-right: 10px;
            }

            .current-schedule {
                color: #aacffc;
            }

            .readonly-message {
                color: #fffc9c;
                text-decoration:none;
            }
        }
    }

    .content-holder {
        height: calc(100% - 70px);
        padding: 10px;
        border-radius: 10px 10px 10px 10px;
        background: linear-gradient(to bottom, #f8f9fb 0, #dce1e7 100%);
        box-shadow: 0 0 22px 1px #333333;

        > div {
            height: 100%;
        }
    }
}
