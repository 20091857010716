#mobileHardwareAssignment, #mobileHardwareRegistration, #mobileCommunity {

    b, a, textarea, legend, select, button, label {
        font-size: 24px;
    }
    //font-size: xx-large;
    a {
        text-decoration: none;
        color: blue;
        margin-left: 10px;
    }

    textarea {
        width: 95%;
        margin: 0 auto;
        border: 2px solid #6387b7;
        height: 140px;
        padding: 5px;
        font-size: xx-large;
    }

    h1 {
        text-align: center;
        text-transform: capitalize;
        font-size: 30px;
    }

    fieldset {
        text-align: center;
        width: 80%;
        margin: 15px auto;
    }

    .save-area {
        text-align: center;
    }

    .go-button {
        width: 260px !important;
        height: 60px;
        color: white;
        font-weight: bold;
        text-align: center;
        vertical-align: central;
        padding-top: 20px;
        font-size: 30px;
    }

    select {
        width: 95%;
        margin: 0 auto;
        padding: 4px;
        height: 60px;
    }

    #router {
        margin-top: 20px;
        margin-bottom: 60px;
        position: relative;

        a {
            font-size: 19px !important;
        }
    }

    .spacer {
        margin-left: 5px;
        margin-right: 5px;
        position: absolute;
        font-size: 40px;
    }
}
