.switch-community-page {
    padding: 0;
    margin: 0;
    height: 100%;
    background: #f9f9fb;
    font-family: "Segoe UI", sans-serif;

    a {
        text-decoration: none;
        color: blue;
        margin-left: 10px;
    }

    #dispatchPortalLink {
        text-decoration: none;
        color: blue;
        margin-left: 10px;
    }

    p.community {
        border: 1px solid black;
        cursor: pointer;
        padding: 30px 0;
        margin: 8px auto;
        text-align: center;
        width: 90%;
        font-size: 20px;
    }

    h1 {
        text-align: center;
        font-size: 1.5em;
    }

    div {
        height: 100%;
    }

    .loader-cover, .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
    }

    .loader-cover {
        background: #ccc;
        opacity: 0.5;
    }

    .loader {
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }

}