.login {
    h1 {
        text-align: center;
        margin-top: 40px;
        font-size: 30px;
        color: #42454C;
    }

    .login-form {
        text-align: center;

        button.green {
            width: 200px;
            margin-top: 5px;
        }

        p {
            font-size: 12px;
            text-align: center;
            color: #555;
        }

        button.mimic-link {
            font-size: 10px;
            font-weight: bold;
            color: #555;
            margin-top: 20px;
        }
    }

    .login-container {
        width: 553px;
        margin: 45px auto 0 auto;
        background: url('../../images/login_body.png') no-repeat;
        padding: 30px;
        height: 260px;
        box-sizing: border-box;
        text-align: center;

        > div {
            display: inline-block;
            text-align: left;
        }

        .login-error{
            color:red;
            font-weight:bold;
        }
    }

    .username-row, .password-row, .email-row {
        margin: 20px 0;

        label {
            color: #4472B3;
            font-size: 18px;
            font-weight: bold;
            width: 150px;
            display: inline-block;
        }

        input {
            width: 260px;
            height: 24px;
            font-size: 18px;
        }
    }

    .remember-username-row {
        label {
            color: #555;
            font-size: 12px;
            margin-left: 38px;
        }
    }

    &.smi-login {

        .login-container {
            padding: 0;
            text-align: center;
            height: auto;

            > div {
                text-align: center;
            }
        }
        
        iframe {
            margin-top: 36px;
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 700px) {
    .login {
        .login-container {
            width: auto;
            height: auto;
            margin: 0;
            background: transparent;

            > div {
                display: block;
            }
        }

        .username-row, .password-row, .email-row {

            label {
                color: #4472B3;
                font-size: 13px;
                font-weight: bold;
                width: auto;
                display: block;
            }

            input {
                width: 100%;
                height: 24px;
                font-size: 18px;
                display: block;
            }
        }

        .remember-username-row {
            label {
                margin-left: 0;
            }
        }
    }
}